import { Typography, Pagination } from "@mui/material/";
import {
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  GridFooterContainer,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";

interface DefaultGridPaginationProps {
  itemsCount: number;
  itemsTitle: string;
  itemsperpage: number;
  totalPages: number;
  currentPage: number;
  setCurrentPage: (page: number) => void; // New prop to set current page
}

// augment the props for `pagination` slot
declare module "@mui/x-data-grid" {
  interface PaginationPropsOverrides {
    itemsCount: number;
    itemsTitle: string;
    itemsperpage: number;
    totalPages: number;
    currentPage: number;
    setCurrentPage: (page: number) => void; // New prop to set current page
  }
}

export const PaginationDatagrid = ({
  itemsCount,
  itemsTitle,
  itemsperpage,
  totalPages,
  currentPage,
  setCurrentPage,
}: DefaultGridPaginationProps) => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const [localPage, setLocalPage] = useState(currentPage);

  const paginationKey = `${itemsCount}-${totalPages}`;

  const handlePageChange = (_event: any, value: number) => {
    apiRef.current.setPage(value - 1); // DataGrid expects 0-indexed pages
    setCurrentPage(value); // Update the current page in the parent
  };

  return (
    <GridFooterContainer
      key={paginationKey}
      sx={{ border: "none", width: "100%", padding: "8px" }}
    >
      <Typography
        variant="body2"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        {itemsCount} {itemsTitle}
      </Typography>
      <Pagination
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
        color="standard"
        count={Math.ceil(itemsCount / itemsperpage)} // Total page count calculation
        page={currentPage - 1}
        onChange={handlePageChange}
        showFirstButton
        showLastButton
        // Pass down the function
      />
    </GridFooterContainer>
  );
};
