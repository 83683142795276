import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import * as XLSX from "xlsx";
import moment from "moment";
import { FormHelperText } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { FaDownload } from "react-icons/fa";
import "./ExcelToJsonConverter.css";

interface Props {
  onJsonExport: (data: any[]) => void;
}

export interface ExcelToJsonConverterRef {
  triggerFileInput: () => void;
}

const ExcelToJsonConverter = forwardRef<ExcelToJsonConverterRef, Props>(
  ({ onJsonExport }, ref) => {
    const [fileName, setFileName] = useState<string | null>(null);
    const [dragOver, setDragOver] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    useImperativeHandle(ref, () => ({
      triggerFileInput() {
        if (fileInputRef.current) {
          fileInputRef.current.click();
        }
      },
    }));

    const handleFileUpload = (file: File) => {
      if (!file.name.endsWith(".xls") && !file.name.endsWith(".xlsx")) {
        setErrorMessage(
          "Opps! something is wrong. Please upload an Excel file."
        );
        return;
      }

      setErrorMessage(null);

      const reader = new FileReader();

      reader.onload = (event) => {
        if (event.target && event.target.result) {
          const binaryString = event.target.result as string;
          const workbook = XLSX.read(binaryString, {
            type: "binary",
            cellDates: true,
          });

          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];

          const data = XLSX.utils.sheet_to_json(sheet, {
            header: 1,
          });

          const nonEmptyRows = data.filter((row: any) =>
            row.some((cell: any) => cell !== "")
          );

          if (nonEmptyRows.length > 100) {
            setErrorMessage("Ce fichier est trop long (max 100).");
            return;
          }

          const headers = nonEmptyRows[0] as string[];

          const formattedData = nonEmptyRows.slice(1).map((row: any) => {
            const obj: any = {};
            headers.forEach((header: string, index: number) => {
              let cellValue = row[index];
              if (cellValue instanceof Date) {
                cellValue = moment(cellValue)
                  .add(1, "day")
                  .format("DD/MM/YYYY");
              } else if (cellValue === undefined) {
                cellValue = ""; // Fill empty cells with empty strings
              }
              obj[header] = cellValue;
              if (header == "Quand") {
                const today = new Date();
                const dd = String(today.getDate()).padStart(2, "0");
                const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
                const yyyy = today.getFullYear();
                const defaultDate = `${dd}/${mm}/${yyyy}`;
                obj[header] = defaultDate;
              }
            });

            return obj;
          });

          onJsonExport(formattedData);
          setFileName(file.name);
        } else {
          console.error("Failed to read the file.");
        }
      };

      reader.readAsBinaryString(file);
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      setDragOver(false);
      const file = e.dataTransfer.files?.[0];
      if (file) {
        handleFileUpload(file);
      }
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      setDragOver(true);
    };

    const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      setDragOver(true);
    };

    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      setDragOver(false);
    };

    return (
      <div
        style={{
          margin: "16px 16px 16px 0px",
          width: `${fileName ? "auto" : "100%"}`,
        }}
      >
        <label htmlFor="inputFile" className="label-file">
          <div
            className={`drop-area ${dragOver ? "drag-over" : ""} ${
              fileName ? "file-uploaded" : ""
            }`}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                height: !fileName ? "45vh" : "",
              }}
            >
              {!fileName && (
                <>
                  <FaDownload style={{ fontSize: "24px", margin: "10px" }} />
                  <p style={{ padding: "10px", display: "flex" }}>
                    Déposer votre fichier ici ou{"  "}
                    <div className="input2">
                      {" "}
                      &nbsp;Cliquez pour selectionner
                    </div>
                  </p>
                </>
              )}
              {fileName && (
                <div className="file-name" style={{ display: "block" }}>
                  Nom du fichier : {fileName}
                </div>
              )}
            </div>
            <input
              id="inputFile"
              type="file"
              accept=".xls,.xlsx"
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) {
                  handleFileUpload(file);
                }
              }}
              className="input-file"
              ref={fileInputRef}
            />
          </div>
        </label>
        {errorMessage && (
          <FormHelperText error>
            <InfoOutlinedIcon />
            {errorMessage}
          </FormHelperText>
        )}
      </div>
    );
  }
);

export default ExcelToJsonConverter;
