import React, { useEffect, useState } from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/logo_tout_faire_jaune.svg";

import { generateRoutes, routesComponent } from "../../Routes";
import { useAccount, useMsal } from "@azure/msal-react";
import { Avatar, ListSubheader, Menu, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import { setDrawerClosed, setDrawerOpen } from "../../redux/drawerActions";
import { setSubDrawerOpen } from "../../redux/subDrawerActions";

const drawerWidth = 240;
// Open Close css animation ////////////////////////////////////////
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "black!important",

  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

type MiniDrawerProps = {
  children: React.ReactNode;
  width?: string;
  setWidth?: (width: string) => void;
};

export default function MiniDrawer({
  children,
  width,
  setWidth,
}: MiniDrawerProps) {
  const open = useSelector((state: RootState) => state.drawer.isOpen);
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const { instance } = useMsal();
  const [anchorEl, setAnchorEl] = useState(null);
  const routesTest = generateRoutes(localStorage.getItem("rights") || "");

  const theme = useTheme();
  const [openMenu, setOpenMenu] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    const handleResize = () => {
      const isLargeScreen = window.matchMedia("(min-width: 1024px)").matches;
      const isXLargeScreen = window.matchMedia("(min-width: 1440px)").matches;
      const isXXLargeScreen = window.matchMedia("(min-width: 2560px)").matches;

      if (open) {
        // Le menu est ouvert
        if (setWidth) {
          if (isXXLargeScreen) {
            setWidth("89vw"); // Écran de 2560px et plus
          } else if (isXLargeScreen) {
            setWidth("81vw"); // Écran de 1440px et plus
          } else if (isLargeScreen) {
            setWidth("74vw"); // Écran de 1024px et plus
          }
        }
      } else {
        // Le menu est fermé
        if (setWidth) {
          if (isXXLargeScreen) {
            setWidth("95vw"); // Écran de 2560px et plus
          } else if (isXLargeScreen) {
            setWidth("92vw"); // Écran de 1440px et plus
          } else if (isLargeScreen) {
            setWidth("90vw"); // Écran de 1024px et plus
          }
        }
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [open, width, setWidth]);

  function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }
  const logout = () => {
    instance
      .logout()
      .finally(() => {
        console.log("logout reussi");
        window.location.href = "/";
      })
      .catch((err) => console.log(err));
  };

  const handleAvatarClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    console.log("Logout clicked");
    logout();
    handleMenuClose();
  };

  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }
  const handleMenuClick = (routeName: string) => {
    setOpenMenu((prev) => ({
      ...prev,
      [routeName]: !prev[routeName],
    }));
  };

  const openSubmenu = useSelector((state: RootState) => state.subDrawer.isOpen);

  const handleClick = (routeName: string) => {
    if (openSubmenu === routeName) {
      dispatch(setSubDrawerOpen(null));
    } else {
      dispatch(setSubDrawerOpen(routeName));
    }
    handleMenuClick(routeName);
  };

  const handleDrawerOpen = () => {
    dispatch(setDrawerOpen());
    if (setWidth) {
      setWidth("82vw");
    }
  };

  const handleDrawerClose = () => {
    dispatch(setDrawerClosed());
    if (setWidth) {
      setWidth("93vw");
    }
  };

  const users = useMsal().accounts;
  const user = useAccount(users[0]);

  console.log("fff", user);
  console.log("routes", routesTest);
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{
          backgroundColor: "black",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            style={{ display: "flex", alignItems: "center", color: "white" }}
          >
            <img
              src={logo}
              alt="Logo"
              style={{ height: "50px", width: "auto", fill: "red" }}
            />
            Tout faire - Base Utilitaire
          </Typography>
        </Toolbar>
        <Toolbar>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ padding: "8px" }}>{user?.name}</div>
            {user?.name ? (
              <>
                <Avatar
                  {...stringAvatar(user?.name)}
                  onClick={handleAvatarClick}
                  style={{ cursor: "pointer" }}
                />
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                >
                  <MenuItem onClick={handleLogout}>Déconnexion</MenuItem>
                </Menu>
              </>
            ) : null}
            <div style={{ position: "relative" }}></div>

            <div style={{ position: "relative" }}>
              {/* <FaSignOutAlt
                style={{ cursor: "pointer", color: "#FFD100" }}
                onClick={logout}
              /> */}
              <div
                style={{
                  position: "absolute",
                  top: "100%",
                  right: "0",
                  backgroundColor: "#fff",
                  borderRadius: "5px",
                  boxShadow: "0px 0px 5px rgba(0,0,0,0.5)",
                  padding: "5px 0",
                  display: "none",
                }}
              >
                <div
                  style={{
                    padding: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    // Add code to handle logout here
                    console.log("Logout clicked");
                  }}
                >
                  Deconnexion
                </div>
              </div>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        PaperProps={{ sx: { bgcolor: "#262626" } }}
      >
        <DrawerHeader sx={{ bgcolor: "text.primary" }}>
          <IconButton onClick={handleDrawerClose} style={{ color: "white" }}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List style={{ maxWidth: "100%", padding: "8px" }}>
          {Object.keys(routesTest).map((routeName) => {
            const route = routesTest[routeName];

            // Check if the route is "Accueil"
            if (routeName === "Accueil") {
              return (
                <React.Fragment key={routeName}>
                  <Link
                    to={route.path!}
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <ListItem
                      disablePadding
                      sx={{
                        display: "flex",
                        "&.Mui-selected": {
                          bgcolor: "#FFD100",
                          borderRadius: "8px",
                          color: "black!important",
                        },
                        color: "white!important",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      selected={route.path === pathname}
                    >
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            color: route.path === pathname ? "black" : "white",
                          }}
                        >
                          {route.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={routeName}
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                </React.Fragment>
              );
            }

            return (
              <React.Fragment key={routeName}>
                {routeName === "Plateforme" && (
                  <Divider
                    sx={{
                      bgcolor: "gray",
                      my: 1,
                      display: open ? "flex" : "none",
                    }}
                  />
                )}

                <ListSubheader
                  sx={{
                    bgcolor: "#262626",
                    color: "White",
                    borderRadius: "8px",
                    display: open ? "flex" : "none",

                    fontWeight: "bold",
                  }}
                >
                  {routeName}
                </ListSubheader>

                {route.subRoutes
                  ? Object.keys(route.subRoutes).map((subRouteName) => {
                      const subRoute = route.subRoutes![subRouteName];
                      const nameRoute = subRoute.path;
                      const icon = subRoute.icon;

                      return (
                        <React.Fragment key={nameRoute}>
                          <Link
                            to={nameRoute!}
                            style={{ textDecoration: "none", color: "white" }}
                          >
                            <ListItem
                              disablePadding
                              sx={{
                                display: "flex",
                                "&.Mui-selected": {
                                  bgcolor: "#FFD100",
                                  borderRadius: "8px",
                                  color: "black!important",
                                },
                                color: "white!important",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              selected={nameRoute === pathname}
                            >
                              <ListItemButton
                                sx={{
                                  minHeight: 48,
                                  justifyContent: open ? "initial" : "center",
                                  px: 2.5,
                                }}
                              >
                                <ListItemIcon
                                  sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : "auto",
                                    justifyContent: "center",
                                    color:
                                      nameRoute === pathname
                                        ? "black"
                                        : "white",
                                  }}
                                >
                                  {icon}
                                </ListItemIcon>
                                <ListItemText
                                  primary={subRouteName}
                                  sx={{ opacity: open ? 1 : 0 }}
                                />
                              </ListItemButton>
                            </ListItem>
                          </Link>
                        </React.Fragment>
                      );
                    })
                  : null}

                <Divider
                  sx={{
                    bgcolor: "gray",
                    my: 1,
                    display: open ? "flex" : "none",
                  }}
                />
              </React.Fragment>
            );
          })}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1 }}>
        {/* <DrawerHeader /> */}
        {children}
      </Box>
    </Box>
  );
}
